import React, { useState } from "react";
import {
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import styled from "styled-components";

const Section = styled.section`
  color: #fff; /* White text */
`;

const Container = styled.div`
  font-size: 1.3rem; /* Adjust font size */
`;

const Title = styled.h1`
  font-size: 1.3rem; /* Adjust font size */
  font-weight: bold;
`;

const Form = styled.form`
  margin: auto;
  padding: 1rem;
`;

const Label = styled.label`
  color: #fff; /* White text */
`;

const Input = styled.input`
  border-radius: 5px;
  background-color: #fff;
  color: black;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border: none;
`;

const Button = styled.button`
  background-color: var(--purple); /* Blue background */
  color: #fff; /* White text */
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  display: block;
  margin: 0 auto; /* Center horizontally */

  &:hover {
    background-color: #007bff; /* Darker blue on hover */
  }

  &:disabled {
    opacity: 0.5; /* Reduce opacity when disabled */
    cursor: not-allowed; /* Change cursor when disabled */
  }
`;

const Message = styled.div`
  padding: 1rem;
  font-weight: bold;
  border-radius: 0.25rem;
  color: #fff;
`;

const SocialList = styled.div`
  display: flex;
  justify-content: center;
`;

const SocialLink = styled.a`
  margin-right: 1rem;
  color: #fff; /* Black text */

  &:hover {
    color: #007bff; /* Blue on hover */
  }
`;

function DataEntryForm() {
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation check
    if (!email) {
      setMessage("Please enter a valid email address.");
      return;
    }

    setSubmitting(true);
    setMessage("Submitting..");

    try {
      const formData = new FormData(e.target);
      const keyValuePairs = [];
      for (const pair of formData.entries()) {
        keyValuePairs.push(pair[0] + "=" + pair[1]);
      }
      const formDataString = keyValuePairs.join("&");

      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbx2NwcclkJH_gvi34GwxYJFm1RRS1DAYZolKhK67rmGB07OkjtU3gRb3TsLvhjDW5Q0fw/exec",
        {
          method: "POST",
          body: formDataString,
          headers: {
            "Content-Type": "text/plain;charset=utf-8",
          },
        }
      );

      if (response.ok) {
        setMessage("Subscribed Successfully!");
        setEmail("");
      } else {
        throw new Error("Failed to Subscribe");
      }
    } catch (error) {
      console.error(error);
      setMessage("An error occurred while submitting the form.");
    } finally {
      setSubmitting(false);
      setTimeout(() => {
        setMessage("");
      }, 2600);
    }
  };

  return (
    <div>
      <Section>
        <Container>
          <Title>Subscribe us for Update</Title>
        </Container>
      </Section>
      <Form onSubmit={handleSubmit}>
        <Container>
          <div>
            <Input
              type="email"
              placeholder="Enter Your Email"
              name="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <Button type="submit" disabled={submitting} id="submit-button">
            {submitting ? "Submitting.." : "subscribe"}
          </Button>
        </Container>
      </Form>
      <Container>
        <Message className={message ? "block" : "hidden"}>{message}</Message>
        <SocialList>
          <SocialLink
            href="https://www.facebook.com/profile.php?id=61557375825237&mibextid=ZbWKwL"
            target="_blank"
          >
            <FaFacebook />
          </SocialLink>
          <SocialLink
            href="https://www.linkedin.com/company/mithila-stack/"
            target="_blank"
          >
            <FaLinkedin />
          </SocialLink>
          <SocialLink
            href="https://www.instagram.com/mithilastack?igsh=MWZxMTYwYjl4cnFtcQ=="
            target="_blank"
          >
            <FaInstagram />
          </SocialLink>
          <SocialLink href="https://twitter.com/MithilaStack" target="_blank">
            <FaTwitter />
          </SocialLink>
          <SocialLink
            href="https://www.youtube.com/channel/UCal5EaAQTzvXQk3LXk2FUWw"
            target="_blank"
          >
            <FaYoutube />
          </SocialLink>
        </SocialList>
      </Container>
    </div>
  );
}

export default DataEntryForm;

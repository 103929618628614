import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../assets/logo-min.svg";
import MobileMenu from "./MobileMenu";
import Calendly from "../components/Calendly";
import Formpopup from "../components/FormPopUp/Formpopup";


const HeaderContainer = styled.header`
  background-color: rgba(55, 74, 89, 0.85);
  color: white;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 100;
  margin-top: 15px;
  margin-bottom: 1.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  

  @media only screen and (max-width: 40em) {
    padding: 1rem;
  }
`;

const Logo = styled.a`
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  img {
    width: 2rem;
    height: auto;
    margin-right: 0.5rem;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media only screen and (max-width: 48em) {
    display: none;
  }
  a {
    font-weight: 600;
    line-height: 1.5;
    color: white;
    text-decoration: none;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 0;
      height: 2px;
      background: var(--purple);
      transition: width 0.3s ease-in-out;
    }

    &:hover::after {
      width: 100%;
    }
  }
`;

const Button = styled.button`
  background-color: var(--purple);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: var(--white);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
  }
  &:focus {
    transform: scale(0.9);
  }
  @media only screen and (max-width: 40em) {
    font-size: 1.2rem;
    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;

const HamburgerBtn = styled.button`
  display: none;

  @media only screen and (max-width: 48em) {
    display: block;
  }

  background-color: transparent;
  border: none;
  cursor: pointer;
  span {
    display: block;
    width: 2rem;
    height: 2px;
    margin: 4px 0;
    background-color: var(--white);
    transition: transform 0.3s ease;
  }

  ${({ clicked }) =>
    clicked &&
    `
    span:nth-child(1) {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
    span:nth-child(2) {
      opacity: 0;
    }
    span:nth-child(3) {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  `}
`;

const Header = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isOpenCalendly, setIsOpenCalendly] = useState(false);
  const [isLoading, setIsLoading] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const [click, setClick] = useState(false);
  const ref = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  function goTo(time, id) {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }, time);
  }

  const scrollUp = (id, e) => {
    e.preventDefault();

    if (location.pathname !== "/") {
      navigate("/");
      goTo(300, id);
    } else {
      goTo(100, id);
    }
  };

  const handleMenuClick = (id, e) => {
    setClick(!click);
    scrollUp(id, e);
  };

  useEffect(() => {
    const element = ref.current;
    const mq = window.matchMedia("(max-width: 40em)");

    const animationOptions = {
      position: "fixed",
      // top: mq.matches ? 0 : "0.2rem",
      left: mq.matches ? 0 : "0.5rem",
      right: mq.matches ? 0 : "0.5rem",
      padding: mq.matches ? "1rem 1rem" : "1rem 1rem",
      borderRadius: mq.matches ? "0 0 50px 50px" : "50px",
      border: mq.matches ? "2px solid white" : "3px solid white",
      duration: 1,
      ease: "power1.out",
      scrollTrigger: {
        trigger: element,
        start: mq.matches ? "bottom+=200 top" : "bottom+=300 top",
        end: mq.matches ? "+=100" : "+=250",
        scrub: true,
      },
    };

    gsap.to(element, animationOptions);

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setClick(false);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const openCalendly = () => {
    setIsOpenCalendly(true);
  };

  const closeCalendly = () => {
    setIsOpenCalendly(false);
  };

  return (
    
      <HeaderContainer ref={ref}>
        <Logo href={location.pathname === "/" ? "/" : "/"}>
          <img src={logo} alt="MithilaStack Logo" />
          <h3>MithilaStack</h3>
        </Logo>
        <Nav>
          <a href="#home" onClick={(e) => scrollUp("home", e)}>
            Home
          </a>
          <a href="#about" onClick={(e) => scrollUp("about", e)}>
            About Us
          </a>
          <a href="#team" onClick={(e) => scrollUp("team", e)}>
            Team
          </a>
          <a href="#services" onClick={(e) => scrollUp("services", e)}>
            Services
          </a>
          <a href="#technology" onClick={(e) => scrollUp("technology", e)}>
            Technology
          </a>
          <a href="#product" onClick={(e) => scrollUp("product", e)}>
            Product
          </a>
          <Link to="/Careers">Careers</Link>

          {!isLoading && (
            <>
              <Button onClick={openCalendly}>Schedule a Meeting</Button>
            </>
          )}
          {isPopupOpen && <Formpopup onClose={closePopup} />}
          {isOpenCalendly && (
            <Calendly isOpen={isOpenCalendly} onClose={closeCalendly} />
          )}
        </Nav>
        <HamburgerBtn
          aria-label="Open Menu"
          clicked={click}
          onClick={() => setClick(!click)}
        >
          <span></span>
          <span></span>
          <span></span>
        </HamburgerBtn>
        <MobileMenu clicked={click} handleMenuClick={handleMenuClick} />
      </HeaderContainer>
    
  );
};

export default Header;
